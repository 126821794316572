<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="row full-width">
        <div class="col-md-6 fv-row">
          <el-input
              v-model="filters.search"
              class="m-2"
              size="large"
              placeholder="Please Input"
              :suffix-icon="Search"
          ></el-input>
        </div>
        <div class="col-md-6 fv-row">
          <el-select clearable v-model="filters.status" class="m-2 select-display-block" placeholder="Select Status"
                     size="large">
            <el-option
                v-for="item in filterOptions.statuses"
                :label="item.label"
                :value="item.value"
                :key="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
        <template v-slot:cell-image="{ row: data }">
          <div class="demo-type">
            <el-image
                class="image-in-list"
                :src="data.image"
                :preview-src-list="[data.image]"
                :initial-index="1"
                fit="cover"
            />
          </div>
        </template>
        <template v-slot:cell-first_name="{ row: data }">{{ data.provider ? data.provider.first_name : '' }}</template>
        <template v-slot:cell-last_name="{ row: data }">{{ data.provider ? data.provider.last_name : '' }}</template>
        <template v-slot:cell-phone="{ row: data }">{{ data.provider ? data.provider.phone : '' }}</template>
        <template v-slot:cell-email="{ row: data }">{{ data.provider ? data.provider.email : '' }}</template>
        <template v-slot:cell-status="{ row: data }">
                    <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.status.class}`">{{
                        data.status.label
                      }}</span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-row style="width: 200px;" v-if="data.status && data.status.value == 0">
            <el-button type="primary" v-can="'providers.update'" size="small" @click="confirm(data.id)">Confirm</el-button>
            <el-button type="danger" v-can="'providers.update'" size="small" @click="reject(data.id)">Reject</el-button>
          </el-row>
          <span v-else>-</span>
        </template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getCities} from "@/core/helpers/remote_data";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "provider-data-changes-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "New Image", key: "image", sortable: false,},
      {name: "First Name", key: "first_name", sortable: true,},
      {name: "Last Name", key: "last_name", sortable: true,},
      {name: "Phone", key: "phone", sortable: false,},
      {name: "Email", key: "email", sortable: false,},
      {name: "Status", key: "status", sortable: false,},
      {name: "Actions", key: "actions",},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      page: 1,
      per_page: 10
    });

    const filterOptions = ref({statuses: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Provider Profile Picture Requests", [], null);
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.PROVIDER_DATA_CHANGE.filter_options);
        filterOptions.value.statuses = response.data.data.data_change_statuses;
      } catch (e) {
        handleError(e)
      }
    }
    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.PROVIDER_DATA_CHANGE.list, filters.value);
        let data = response.data.data;
        tableData.value = data.provider_data_changes;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });

    const createResource = () => {
      router.push({name: 'create-provider'});
    }
    const editResource = (id) => {
      router.push({name: 'edit-provider', params: {id: id}});
    }
    const viewResource = (id) => {
      router.push({name: 'view-provider', params: {id: id}});
    }

    const confirm = async (id) => {
      var checkConfirm = await confirmGeneralMessage("Confirm", "Are you sure confirm data ?", "Yes");
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Pending ...'})
        ApiAxiosService.get(APIs.PROVIDER_DATA_CHANGE.confirm(id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
        }).catch(function (error) {
          handleError(error, 'swal');
        });

      }
    }
    const reject = async (id) => {
      var checkConfirm = await confirmGeneralMessage("Reject", "Are you sure reject data ?", "Yes");
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Pending ...'})
        ApiAxiosService.get(APIs.PROVIDER_DATA_CHANGE.reject(id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
        }).catch(function (error) {
          handleError(error, 'swal');
        });

      }
    }
    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      fetchFilerOptions,
      filterOptions,

      confirm,
      reject,
      Search, Edit, Delete, View

    };
  },

});
</script>
